import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

const ProductList = ({ product }) => {
  const {
    id,
    handle,
    title,
    images: [firstImage],
    variants: [firstVariant]
  } = product;

  return (
    <li key={id} className='w-1/3 p-4'>
      <Link to={`/shop/product/${handle}/`}>
        {firstImage && firstImage.localFile &&
          <Img
            fluid={firstImage.localFile.childImageSharp.fluid}
            alt={handle}
          />}
      </Link>
      <h3>{title}</h3>
      <p>{firstVariant.price}</p>
    </li>
  );
};

const ProductGrid = (products) => {
  return (
    <ul className='w-full flex flex-wrap m-8'>
      {products.map(({ node }) => (
          <ProductList product={node} key={node.id} />))}
    </ul>
  );
};

const CollectionList = ({ collection }) => {
  const {
    handle,
    title,
    image
  } = collection;
  return (
    <li className='w-1/3 p-4'>
      <h3 className='text-2xl text-center my-2'>{title}</h3>
      <Link to={`/shop/${handle}/`}>
        {image && image.localFile &&
         <Img
           fluid={image.localFile.childImageSharp.fluid}
           alt={handle}
         />}
      </Link>
    </li>
  );
};

const CollectionGrid = (collections) => {
  return (
    <ul className='w-full flex flex-wrap m-8'>
      {collections.map(({ node }) => (
        <CollectionList collection={node} key={node.id} />))}
    </ul>
  );
};


const Shop = ({ data }) => {
  const { allShopifyProduct, allShopifyCollection } = data;
  return (
    <>
      <section className='m-4'>
        <Img className='w-2/5 m-auto my-4 mt-8' fluid={data.shopBanner.childImageSharp.fluid}/>
        <ul>
          {allShopifyCollection.edges
           ? CollectionGrid(allShopifyCollection.edges)
           : <p>No Collections found.</p>}
        </ul>
        <Img fluid={data.newBanner.childImageSharp.fluid} className='my-4 mt-16 w-1/3 m-auto'/>
        <h2 className='text-3xl hidden'>Newest Products</h2>
        {allShopifyProduct.edges
          ? ProductGrid(allShopifyProduct.edges)
          : <p>No Products found!</p>}
      </section>
    </>
  );
};

export const query =
  graphql`
      query {
        allShopifyProduct(
          limit: 8
          sort: {
            fields: [createdAt]
            order: DESC
          }
        ) {
          edges {
            node {
              id
              title
              handle
              createdAt
              images {
                id
                originalSrc
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 910) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
              variants {
                price
              }
            }
          }
        }
        allShopifyCollection {
          edges {
            node {
              id
              handle
              title
              image {
                id
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 910) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
            }
          }
        }
       shopBanner: file(relativePath: { eq: "shop-title.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
       newBanner: file(relativePath: { eq: "shop-new.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `;

export default Shop;
